.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.Board-container{
  display: grid;
  grid-template-columns: repeat(8, 1fr);
  grid-template-rows: repeat(8, 1fr);
  grid-gap: 0;
  width: 400px

}

.BoardTile{
  height: 50px;
  width: 50px;
  border: 1px solid black;
}

.BoardTile:hover{

  /* background-color: #282c34aa; */
}

.tile-black{
  background-color: black;
  color: white;

}

.b-color1{
  background-color: #42a5f5;
}

.b-color2{
  background-color: #c9c739;
}

.b-color3{
  background-color: black;
}

.b-color4{
  background-color: #f06292;
}

.b-color5{
  background-color: #ad1457;
}

.b-color6{
  background-color: #c2185b;
}

.b-color7{
  background-color: #4caf50;
}

.b-color7{
  background-color: #4caf50;
}

.b-color7{
  background-color: #4caf50;
}